import React, { forwardRef, useEffect, useState } from 'react'

import {
  PrismicLinkType,
  PrismicStoreLocation,
} from 'src/typings/generated/graphql'

import Radar from 'radar-sdk-js'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'radar-sdk-js/dist/radar.css'
import {
  getStoreLocationDisplayName,
  getStoreLocationStage,
} from 'src/utils/storeLocationHelper'
import useSupercluster from 'use-supercluster'
import { PointFeature } from 'supercluster'
import { BBox, GeoJsonProperties } from 'geojson'

export type LocatorContent = {
  anchor?: string
  callout?: string
  link?: PrismicLinkType
  linkText?: string
}

type Props = {
  userCurrentLocation: any
  locations: PrismicStoreLocation[]
}

const RadarNoLocationsMap = forwardRef<undefined, Props>(
  ({ locations, userCurrentLocation }) => {
    const [markersLoaded, setMarkersLoaded] = useState(false)
    let map: any | null = null

    // Map Configurations
    const viewportWidth =
      typeof window !== 'undefined' ? window.innerWidth : 1024
    const mapConfig = {
      center: { lat: 37.4822807172226, lng: -95.994254625 },
      zoom: viewportWidth && viewportWidth < 767 ? 3 : 4,
    }
    const [mapBounds] = useState<BBox>([
      -128.144531, 21.125498, -65.917969, 53.173119,
    ])
    const [mapZoom] = useState(mapConfig.zoom)

    const points: Array<PointFeature<GeoJsonProperties>> = locations?.map(
      (location, i) => {
        const locationStage = getStoreLocationStage(location)
        const locationName = getStoreLocationDisplayName(location)
        const locationZip =
          location.data.override_external_zip_code ??
          location.data.external_location_data?.postal_code ??
          ''
        return {
          type: 'Feature',
          properties: {
            cluster: false,
            locationId: location.uid,
            locationName,
            index: i,
            locationStage,
            locationZip,
          },
          geometry: {
            type: 'Point',
            coordinates: [
              location.data.coordinates?.longitude ?? 0,
              location.data.coordinates?.latitude ?? 0,
            ],
          },
        }
      }
    )

    const { clusters } = useSupercluster({
      points,
      bounds: mapBounds,
      zoom: mapZoom,
      options: { radius: 75 },
    })

    useEffect(() => {
      // If process key is undefined. Return and skip initialization
      if (!process.env.GATSBY_RADAR_API_KEY) {
        return
      }

      Radar.initialize(`${process.env.GATSBY_RADAR_API_KEY}`)

      // create a map
      map = Radar.ui.map({
        container: 'map',
        style: 'radar-default-v1',
        center: [-95.994254625, 17.4822807172226],
        zoom: viewportWidth < 767 ? 2 : 3,
        boxZoom: false,
        interactive: false,
        scrollZoom: false,
      })

      if (clusters.length > 0) {
        // add a marker to the map
        // eslint-disable-next-line array-callback-return
        clusters.map((pin: any, index: number) => {
          const currentMarker = document.getElementById(`point-${index}`)
          if (currentMarker) {
            Radar.ui
              .marker({ element: currentMarker })
              .setLngLat([
                pin?.geometry.coordinates[0],
                pin?.geometry.coordinates[1],
              ])
              .addTo(map)
          }
          if (clusters.length === index + 1) {
            setMarkersLoaded(true)
          }
        })
      }
    }, [userCurrentLocation, clusters, markersLoaded])

    return (
      <div id="map-container">
        {markersLoaded &&
          clusters?.map((item: any, index: any) =>
            item.properties.point_count ? (
              <div
                id={`point-${index}`}
                style={{
                  width: '30px',
                  height: '30px',
                  background: '#d81c2f',
                  borderRadius: '50%',
                  color: 'white',
                  fontWeight: '700',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <span>{item?.properties?.point_count}</span>
              </div>
            ) : null
          )}
        <div
          id="map"
          style={{ height: '100%', position: 'absolute', width: '100%' }}
        />
      </div>
    )
  }
)

export default RadarNoLocationsMap
