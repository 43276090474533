import React, {
  Dispatch,
  forwardRef,
  SetStateAction,
  useEffect,
  useState,
} from 'react'

import {
  PrismicLinkType,
  PrismicStoreLocation,
} from 'src/typings/generated/graphql'

import Radar from 'radar-sdk-js'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'radar-sdk-js/dist/radar.css'
import {
  getDistanceFromLatLonInKm,
  getStoreLocationDisplayName,
  getStoreLocationStage,
  StoreLocationStage,
} from 'src/utils/storeLocationHelper'

export type LocatorContent = {
  anchor?: string
  callout?: string
  link?: PrismicLinkType
  linkText?: string
}

export interface IVisibleLocation {
  locationIndex: number
  location: PrismicStoreLocation
  distanceFromCurrentLocation: number | null
}

type Props = {
  userCurrentLocation: any
  locations: PrismicStoreLocation[]
  setVisibleLocations?: Dispatch<SetStateAction<IVisibleLocation[]>>
}

type Bounds = [number, number, number, number]

type MapPoint = {
  type: string
  properties: {
    cluster: boolean
    locationId: string
    locationName: string
    index: number
    locationStage: string | StoreLocationStage
    locationZip: string
    distance?: number
  }
  geometry: {
    type: string
    coordinates: [number, number]
  }
}

const RadarLocationsMap = forwardRef<undefined, Props>(
  ({ locations, userCurrentLocation, setVisibleLocations }) => {
    const [mapPoints, setMapPoints] = useState<MapPoint[]>([])
    const [markersLoaded, setMarkersLoaded] = useState(false)
    let map: any | null = null

    const filterLocationsByBounds = (
      allLocations: PrismicStoreLocation[],
      bounds: Bounds
    ) => {
      const [west, south, east, north] = bounds
      return allLocations.filter(location => {
        const longitude = location.data.coordinates?.longitude ?? 0
        const latitude = location.data.coordinates?.latitude ?? 0
        return (
          longitude >= west &&
          longitude <= east &&
          latitude >= south &&
          latitude <= north
        )
      })
    }

    useEffect(() => {
      // If process key is undefined. Return and skip initialization
      if (!process.env.GATSBY_RADAR_API_KEY) {
        return
      }

      const userLongitude = Number(userCurrentLocation?.lng)
      const userLatitude = Number(userCurrentLocation?.lat)

      if (Number.isNaN(userLongitude) || Number.isNaN(userLatitude)) {
        return
      }

      Radar.initialize(`${process.env.GATSBY_RADAR_API_KEY}`)

      // create a map
      map = Radar.ui.map({
        container: 'map',
        style: 'radar-default-v1',
        center: [userLongitude, userLatitude],
        zoom: 10,
        boxZoom: false,
        interactive: false,
        scrollZoom: false,
      })

      const bounds = map.getBounds()

      const filteredPoints = filterLocationsByBounds(locations, [
        bounds.getWest(),
        bounds.getSouth(),
        bounds.getEast(),
        bounds.getNorth(),
      ]).map((location, i) => {
        const locationStage = getStoreLocationStage(location)
        const locationName = getStoreLocationDisplayName(location)
        const locationZip =
          location.data.override_external_zip_code ??
          location.data.external_location_data?.postal_code ??
          ''
        return {
          type: 'Feature',
          properties: {
            cluster: false,
            locationId: location.uid,
            locationName,
            index: i,
            locationStage,
            locationZip,
          },
          geometry: {
            type: 'Point',
            coordinates: [
              location.data.coordinates?.longitude ?? 0,
              location.data.coordinates?.latitude ?? 0,
            ] as [number, number],
          },
        } as MapPoint
      })

      const visibleMarkers: IVisibleLocation[] = filteredPoints.map(
        (location, index) =>
          ({
            locationIndex: index,
            location,
            distanceFromCurrentLocation: 0,
          } as unknown as IVisibleLocation)
      )

      if (setVisibleLocations && typeof setVisibleLocations === 'function') {
        setVisibleLocations(visibleMarkers)
      }

      // Calculate distance from user location to each location
      for (let i: number = 0; i < filteredPoints.length; i += 1) {
        const userLatitudeToString =
          userCurrentLocation === undefined
            ? ''
            : userCurrentLocation.lat.toString()
        const userLongitudeToString =
          userCurrentLocation === undefined
            ? ''
            : userCurrentLocation.lng.toString()

        const distance: number = getDistanceFromLatLonInKm(
          parseInt(userLatitudeToString, 10),
          parseInt(userLongitudeToString, 10),
          filteredPoints[i].geometry.coordinates[1],
          filteredPoints[i].geometry.coordinates[0]
        )
        ;(filteredPoints[i] as any).distance = distance * 0.621371
      }

      filteredPoints.sort((a: any, b: any) => a.distance - b.distance)

      if (filteredPoints.length > 0) {
        setMarkersLoaded(true)
        // add a marker to the map
        // eslint-disable-next-line array-callback-return
        filteredPoints.map((pin: any, index: number) => {
          const currentMarker = document.getElementById(`point-${index}`)
          if (currentMarker) {
            Radar.ui
              .marker({ element: currentMarker })
              .setLngLat([
                pin?.geometry.coordinates[0],
                pin?.geometry.coordinates[1],
              ])
              .addTo(map)
          }
        })
      }

      setMapPoints(filteredPoints)
    }, [userCurrentLocation, markersLoaded])

    return (
      <div id="map-container">
        {markersLoaded &&
          mapPoints?.map((item: any, index: any) => (
            <div
              id={`point-${index}`}
              style={{
                width: '30px',
                height: '30px',
                background: '#d81c2f',
                borderRadius: '50%',
                color: 'white',
                fontWeight: '700',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <span>{index + 1}</span>
            </div>
          ))}
        <div
          id="map"
          style={{ height: '100%', position: 'absolute', width: '100%' }}
        />
      </div>
    )
  }
)

export default RadarLocationsMap
