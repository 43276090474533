// import { graphql, useStaticQuery } from 'gatsby'
import React, { useState } from 'react'
// import googleMapReact from 'google-map-react'
import BertholdTitle from 'src/components/atoms/bertholdTitle'

import * as GlobalStyles from 'src/styles/global.module.scss'
import {
  LocationsQuery,
  // LocationsQuery,
  PrismicLinkType,
  PrismicStoreLocation,
  // PrismicStoreLocation,
} from 'src/typings/generated/graphql'

import { IVisibleLocation } from 'src/components/molecules/locationsMap'
import CustomLink from 'src/utils/customLink'
import { generateRandomId } from 'src/utils/domHelper'
// eslint-disable-next-line import/no-extraneous-dependencies
// eslint-disable-next-line import/no-extraneous-dependencies
import 'radar-sdk-js/dist/radar.css'

import RadarLocationsMap from 'src/components/molecules/radarLocationsMap'
import RadarNoLocationsMap from 'src/components/molecules/radarNoLocationsMap'
import { graphql, useStaticQuery } from 'gatsby'
import * as Styles from './locator.module.scss'

export type LocatorContent = {
  anchor?: string
  callout?: string
  link?: PrismicLinkType
  linkText?: string
}

type Props = {
  content: LocatorContent
}

const RadarLocator = ({ content }: Props) => {
  const [requestedLocation, setRequestedLocation] = useState(false)
  const [userCurrentLocation, setUserCurrentLocation]: any = useState()
  const [visibleLocations, setVisibleLocations] = useState<IVisibleLocation[]>(
    []
  )
  const [zoomValue, setZoomValue]: any = useState(11)

  const visibleLocationCount = visibleLocations.length

  const {
    anchor = generateRandomId(),
    callout = '',
    link,
    linkText = '',
  } = content

  const data: LocationsQuery = useStaticQuery(graphql`
    query Locations {
      allPrismicStoreLocation {
        nodes {
          uid
          data {
            coordinates {
              latitude
              longitude
            }
            external_location_data {
              birthdate
              postal_code
            }
            override_external_zip_code
          }
        }
      }
    }
  `)

  const getUserLocation = () => {
    if (typeof navigator === 'undefined') return

    navigator.geolocation.getCurrentPosition(
      position => {
        const location = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }
        setUserCurrentLocation(location)
      },
      err => {
        setZoomValue(undefined)
        // eslint-disable-next-line no-console
        console.error(err)
      },
      {
        maximumAge: Infinity,
      }
    )
    setRequestedLocation(true)
  }

  if (!requestedLocation) getUserLocation()

  return (
    <section id={anchor} className={Styles.locatorSlice}>
      <div className={Styles.mapContainer} id="map-container">
        <div
          id="map"
          style={{ height: '100%', position: 'absolute', width: '100%' }}
        />
        {zoomValue === undefined ? (
          <RadarNoLocationsMap
            locations={
              data.allPrismicStoreLocation.nodes as PrismicStoreLocation[]
            }
            userCurrentLocation={userCurrentLocation}
          />
        ) : (
          <RadarLocationsMap
            userCurrentLocation={userCurrentLocation}
            locations={
              data.allPrismicStoreLocation.nodes as PrismicStoreLocation[]
            }
            setVisibleLocations={setVisibleLocations}
          />
        )}
      </div>
      <div className={Styles.contentContainer}>
        <div className={Styles.offsetBorder} />
        <div className={Styles.overlay}>
          <div className={Styles.content}>
            <p>
              {userCurrentLocation
                ? `${visibleLocationCount} Locations near you!`
                : ''}
            </p>
            <h2>{BertholdTitle({ text: callout })}</h2>
            {link?.url && (
              <CustomLink
                className={GlobalStyles.whiteGhostButtonResponsive}
                link={link}
              >
                {linkText}
              </CustomLink>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default RadarLocator
